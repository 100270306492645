/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { usePostQuery, useTokenQuery } from "../../hooks/reactQueryHelper";
import {
    genetareToken,
    GetEventDetail,
    GetParticipatedEventUsers,
    GetUpcomingEvents,
    validateInvitedUser,
} from "../../services/Web.type";
import EventDetailsCard from "../../Common/Card/EventDetailsCard";
import CardSlide from "../../Common/Card/CardSlide";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import { useAuth } from "../../module/auth/core/Auth";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import EventDescriptionCard from "../../Common/Card/EventDescriptionCard";
import BlankPage from "../../Common/BlankData/BlankPage";
import { setAuth } from "../../module/auth/core/AuthHelper";
import { toBePartiallyChecked } from "@testing-library/jest-dom/matchers";

const PrivateEventDetails = () => {

    const { saveAuth, setCurrentUser, logout } = useAuth();
    const { slug, id } = useParams();
    const { auth } = useAuth();
    const [showOtherDetails, setShowOtherDetails] = useState(false);

    const [isFree, setIsFree] = useState(false);
    const [isSlotAvailable, setIsSlotAvailable] = useState(false);
    const [isUserjoinded, setIsUserjoinded] = useState(false);
    const [eventID, setEventID] = useState(0);
    const [activeTab, setActiveTab] = useState("eventDetails");
    const [inviteToken, setinviteToken] = useState(null);
    const [isDesktop, setIsDesktop] = useState(false);
    const location = useLocation();
    const additionalData: any = location.state;
    const [tabType, setTabType] = useState(additionalData ?? false);

    const [isjoined, setIsjoined] = useState<boolean>(false);
    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    }; 
    const fullUrl = `${GetEventDetail}/${slug}`;
    const fullURLGetToken = `${genetareToken}/${id}`;
    const navigate = useNavigate();

    const validateInvitedUserQuery = usePostQuery("validateInvitedUser", validateInvitedUser);
    const genetareTokenUserQuery = usePostQuery("genetareTokenUserQuery", fullURLGetToken);
    const getEventDetailQuery = usePostQuery("getEventDetailQuery", fullUrl);
    const isParticipatedEventUrl = `${GetParticipatedEventUsers}/${slug}`;

    const getParticipatedEventUsers = usePostQuery(
        "getParticipatedEventUsers",
        isParticipatedEventUrl
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id != null || id != undefined) {
                    id.trim();
                }
                genetareTokenUserQuery.mutateAsync({ id: id })
            } catch (error) {
                navigate('/error/404');
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        debugger
        if (genetareTokenUserQuery?.isSuccess) {
            setinviteToken(genetareTokenUserQuery?.data?.result?.token);
        }
    }, [genetareTokenUserQuery?.isSuccess]);

    useEffect(() => {
        if (genetareTokenUserQuery?.isError) {
            navigate('/error/404');
        }
    }, [genetareTokenUserQuery?.isError]);

    useEffect(() => {
        const requestUser = async (inviteToken: string) => {
            try {
                validateInvitedUserQuery.mutate({ token: inviteToken });
            } catch (error) {
                console.log(error);
            }
        }
        if (inviteToken) {
            requestUser(inviteToken);
        }
    }, [inviteToken])

    useEffect(() => {
        if (validateInvitedUserQuery?.isSuccess) {
            setAuth(validateInvitedUserQuery?.data?.result);
            saveAuth(validateInvitedUserQuery?.data?.result);
            setCurrentUser(validateInvitedUserQuery?.data?.result);
        }
    }, [validateInvitedUserQuery?.isSuccess]);

    useEffect(() => {
        if (validateInvitedUserQuery?.isError) {
            navigate('/error/404');
        }
    }, [validateInvitedUserQuery?.isError]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getEventDetailQuery.mutateAsync({});
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors here
                navigate("/events");
            }
        };

        fetchData();
    }, [slug]);

    useEffect(() => {
        const fetchAuthData = async () => {
            try {
                await getParticipatedEventUsers.mutateAsync({});
            } catch (error) {
                console.log('error');
            }

        }
        if (auth) {
            fetchAuthData();
        }
    }, [auth]);

    useEffect(() => {
        if (getEventDetailQuery?.isSuccess) {
            setIsFree(getEventDetailQuery?.data?.result?.joiningFees == 0);
            setEventID(getEventDetailQuery?.data?.result?.id);
            setIsSlotAvailable((getEventDetailQuery?.data?.result?.isSlotAvailable));
        }
    }, [getEventDetailQuery?.isSuccess])

    useEffect(() => {
        if (getParticipatedEventUsers?.isSuccess) {
            setIsUserjoinded((getParticipatedEventUsers?.data?.result?.isParticipatedEvent));
        }
    }, [getParticipatedEventUsers?.isSuccess])
    const getUpcomingEventQuery = usePostQuery(
        "getUpcomingEventQuery",
        GetUpcomingEvents,
        {}
    );

    useEffect(() => {
        getUpcomingEventQuery.mutate({
            search: "",
            eventTypeID: [],
            isGetAll: true,
        });
    }, []);

    useEffect(() => {
        if (
            getEventDetailQuery?.error &&
            (getEventDetailQuery.error.response?.status === 400 ||
                getEventDetailQuery.error.response?.status === 404 ||
                getEventDetailQuery.error.response?.status === 500)
        ) {
            navigate("/events");
        }
    }, [getEventDetailQuery?.error, navigate]);




    useEffect(() => {
        if (
            auth
        ) {
            setShowOtherDetails(false);
        } else {
            setShowOtherDetails(true);
        }
    }, [auth]);

    useEffect(() => {
        const checkIsDesktop = () => {
            const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");
            setIsDesktop(desktopMediaQuery.matches);
        };

        checkIsDesktop();

        // Add event listener for changes in screen size
        const resizeListener = () => {
            checkIsDesktop();
        };

        window.addEventListener("resize", resizeListener);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    return (
        <>
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="p-lg-5 pb-lg-3 p-0">
                                    <PageNavigation currentPage={"Event Details"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="px-md-5 pb-md-1 p-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex gap-3 mb-4">
                                        {
                                            tabType && (
                                                <>
                                                    <button
                                                        className={`d-lg-none fw-600 align-items-center border-0 background-light rounded-pill px-3 py-2 ${activeTab === "eventDetails" ? "active bg-default text-black" : "text-5F6368 bg-F3F3F3"
                                                            }`}
                                                        onClick={() => handleTabChange("eventDetails")}
                                                    >
                                                        Event details
                                                    </button>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <>
                                    {isDesktop ? (
                                        <>
                                            {(
                                                <EventDetailsCard
                                                    getEventDetailQuery={getEventDetailQuery}
                                                    slug={slug}
                                                    showOtherDetails={showOtherDetails}
                                                    isjoined={(isSlotAvailable == true && isUserjoinded == false)}
                                                />
                                            )}
                                            {(
                                                <EventDescriptionCard
                                                    getEventDetailQuery={getEventDetailQuery}
                                                    showOtherDetails={showOtherDetails}
                                                    isFree={isFree}
                                                    eventID={eventID}
                                                    isjoined={(isSlotAvailable == true && isUserjoinded == false)}
                                                />
                                            )}
                                        </>
                                    ) : (

                                        <>
                                            {activeTab === "eventDetails" && (
                                                <>
                                                    {(
                                                        <EventDetailsCard
                                                            getEventDetailQuery={getEventDetailQuery}
                                                            slug={slug}
                                                            showOtherDetails={showOtherDetails}
                                                            isjoined={(isSlotAvailable == true && isUserjoinded == false)}
                                                        />
                                                    )}
                                                    {(
                                                        <EventDescriptionCard
                                                            getEventDetailQuery={getEventDetailQuery}
                                                            showOtherDetails={showOtherDetails}
                                                            isFree={isFree}
                                                            eventID={eventID}
                                                            isjoined={(isSlotAvailable == true && isUserjoinded == false)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-footer-light mt-5">
                    <div className="container">
                        <div className="px-md-5 pb-md-2 p-0">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="fw-600 py-5 text-center mb-0 text-uppercase">
                                        upcoming events
                                    </h1>
                                </div>
                                <div className="col-12">
                                    {getUpcomingEventQuery?.data?.result?.length > 0 ? (
                                        <CardSlide getUpcomingEventQuery={getUpcomingEventQuery} pageName={'upcomingEvent'} isdetailpage={false} />
                                    ) : (<BlankPage />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-background ">
                    <DownloadSection />
                </section>
            </main>
        </>
    );
};

export default PrivateEventDetails;
